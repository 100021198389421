import React from 'react'
import './App.css'
import CalcGrid from './CalcGrid'

const defaultSize = 5
const minSize = 1
const maxSize = 26

/**
 * Sample app to demonstrate the CalcGrid component.
 */
export default class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = { width: defaultSize, height: defaultSize }
  }

  handleGridSizeChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value })
  }

  render () {
    return (
      <div className='App'>
        <h1 className='App-h1'>Calc Grid</h1>
        <div className='App-card'>
          <h2 className='App-h2'>Properties</h2>
          <label className='App-label'>
            Width
            <input
              className='App-input'
              name='width'
              type='number'
              min={minSize}
              max={maxSize}
              value={this.state.width}
              onChange={this.handleGridSizeChange}
            />
          </label>
          <label className='App-label'>
            Height
            <input
              className='App-input'
              name='height'
              type='number'
              min={minSize}
              max={maxSize}
              value={this.state.height}
              onChange={this.handleGridSizeChange}
            />
          </label>
        </div>
        <CalcGrid width={parseSize(this.state.width)} height={parseSize(this.state.height)} />
      </div>
    )
  }
}

function parseSize (value) {
  const parsed = parseInt(value, 10)
  return isNaN(parsed) ? defaultSize : Math.min(Math.max(minSize, parsed), maxSize)
}
