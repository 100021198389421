import React from 'react'
import './CalcGrid.css'

/**
 * Calc grid component.
 */
export default class CalcGrid extends React.Component {
  constructor (props) {
    super(props)
    this.state = initState(props)
  }

  /**
   * Reset state when width or height change.
   */
  static getDerivedStateFromProps (nextProps, prevState) {
    const numCols = prevState.colLabels.length
    const numRows = prevState.rowLabels.length
    if (numCols !== nextProps.width || numRows !== nextProps.height) {
      return initState(nextProps)
    } else return null
  }

  componentDidMount () {
    window.addEventListener('click', this.clickOut, false)
    window.addEventListener('keydown', this.pressEscape, false)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.clickOut, false)
    window.removeEventListener('keydown', this.pressEscape, false)
  }

  activateCell = (colLabel, rowLabel) => {
    this.setState({ activeColLabel: colLabel, activeRowLabel: rowLabel })
  }

  deactivateCell = () => this.setState({ activeColLabel: null, activeRowLabel: null })

  clickOut = e => {
    if (e.target.nodeName !== 'INPUT' || !e.target.classList.contains('CalcGrid-cell')) {
      this.deactivateCell()
    }
  }

  pressEscape = e => e.key === 'Escape' && this.deactivateCell()

  handleCellEdit = ({ target: { value } }) => {
    const cellLabel = `${this.state.activeColLabel}${this.state.activeRowLabel}`
    this.setState({ values: { ...this.state.values, [cellLabel]: parseNumber(value) } })
  }

  render () {
    return (
      <div className='CalcGrid'>
        <Header labels={this.state.colLabels} />
        {this.state.rowLabels.map(rowLabel => (
          <Row
            key={rowLabel}
            label={rowLabel}
            colLabels={this.state.colLabels}
            values={this.state.values}
            activeColLabel={rowLabel === this.state.activeRowLabel ? this.state.activeColLabel : null}
            onChange={this.handleCellEdit}
            activateCell={this.activateCell}
          />
        ))}
        <ColProducts
          values={this.state.values}
          colLabels={this.state.colLabels}
          rowLabels={this.state.rowLabels}
        />
      </div>
    )
  }
}

const columnLabel = colIdx => String.fromCharCode(colIdx + 65)
const columnLabels = width => [...Array(width)].map((_, idx) => columnLabel(idx))
const rowLabel = rowIdx => rowIdx + 1
const rowLabels = height => [...Array(height)].map((_, idx) => rowLabel(idx))

/**
 * Initialize grid cell values.
 */
function initValues (width, height) {
  const values = {}
  for (const colLabel of columnLabels(width)) {
    for (const rowLabel of rowLabels(height)) values[`${colLabel}${rowLabel}`] = 0
  }
  return values
}

/**
 * Initialize state.
 */
function initState (props) {
  return {
    colLabels: columnLabels(props.width),
    rowLabels: rowLabels(props.height),
    values: initValues(props.width, props.height),
    activeColLabel: null,
    activeRowLabel: null
  }
}

/**
 * Grid header component, filled with column labels (A, B, C, ...).
 */
function Header (props) {
  return (
    <div className='CalcGrid-row'>
      {props.labels.map((label, idx) => {
        return <span className='CalcGrid-header CalcGrid-cell' key={idx}>{label}</span>
      })}
    </div>
  )
}

/**
 * Editable grid cell component.
 */
function Cell (props) {
  const invalid = typeof props.value === 'number' ? '' : ' invalid'
  const className = `CalcGrid-cell${invalid}`
  if (!props.isActive) {
    return (
      <span className={className} onDoubleClick={props.handleCellActivation}>
        {props.value}
      </span>
    )
  }
  return (
    <input
      className={className}
      type='text'
      value={props.value}
      onChange={props.onChange}
      autoFocus
    />
  )
}

/**
 * Returns the sum of cell values in a row.
 */
function rowSum (values, rowLabel, colLabels) {
  let sum = 0
  for (const colLabel of colLabels) {
    const value = values[`${colLabel}${rowLabel}`]
    if (typeof value !== 'number') return '-'
    sum += value
  }
  return sum
}

/**
 * Grid row component (contains row label, editable cells and row result).
 */
function Row (props) {
  return (
    <div className='CalcGrid-row'>
      <span className='CalcGrid-header CalcGrid-cell'>{props.label}</span>
      {props.colLabels.map(colLabel => (
        <Cell
          key={colLabel}
          value={props.values[`${colLabel}${props.label}`]}
          isActive={colLabel === props.activeColLabel}
          onChange={props.onChange}
          handleCellActivation={() => props.activateCell(colLabel, props.label)}
        />
      ))}
      <span className='CalcGrid-cell CalcGrid-rowsum'>
        {rowSum(props.values, props.label, props.colLabels)}
      </span>
    </div>
  )
}

/**
 * Returns the product of cell values in a column.
 */
function colProduct (values, colLabel, rowLabels) {
  let prod = 1
  for (const rowLabel of rowLabels) {
    const value = values[`${colLabel}${rowLabel}`]
    if (typeof value !== 'number') return '-'
    prod *= value
  }
  return prod
}

/**
 * Last row of the grid (a function component, contains column results).
 */
function ColProducts (props) {
  return (
    <div className='CalcGrid-row'>
      {props.colLabels.map(colLabel => (
        <span key={colLabel} className='CalcGrid-cell CalcGrid-colprod'>
          {colProduct(props.values, colLabel, props.rowLabels)}
        </span>
      ))}
    </div>
  )
}

/**
 * Parses and returns the user supplied cell value.
 */
function parseNumber (value) {
  if (value.trim() === '') return 0
  const parsed = parseFloat(value)
  return (isNaN(parsed) || value.slice(-1) === '.') ? value : parsed
}
